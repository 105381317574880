h1, h2 {
  margin-bottom: 0;
}

.content {
  padding: 0 16px;
}

.banner {
  z-index: 1;
  position: relative;

  &__video {
    width: 100%;
    height: 560px;
    overflow: hidden;
    position: relative;

    video, img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    video {
      z-index: 1;
      top: 50%;
    }

    img {
      z-index: 2;
      top: 0;
    }
  }

  &__layout {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40));
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon-play {
    width: 56px;
    height: 56px;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    align-items: center;
  }

  &__detail {
    max-width: 1204px;
    margin: 64px auto;
    padding: 0 16px;

    @media only screen and (max-width: 991px) {
      padding: 0 16px;
      margin: 32px 0 0 0;
    }
  }

  &__title {
    color: #000000;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;

    @media only screen and (max-width: 991px) {
      padding: 0 16px;
      font-size: 24px;
      line-height: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }


    span {
      color: #000;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      position: relative;

      @media only screen and (max-width: 991px) {
        font-size: 24px;
        line-height: 32px;
      }

      &:before {
        content: '';
        width: 64px;
        height: 8px;
        background-color: #005EA8;
        position: absolute;
        bottom: -24px;

        @media only screen and (max-width: 991px) {
          height: 4px;
          bottom: -16px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  &__description {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding-left: 85px;

    @media only screen and (max-width: 991px) {
      padding: 0 16px;
      margin-top: 40px;
    }
  }

  &__box-video {
    position: fixed;
    height: 100vh;
    z-index: 100000;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(30,30,30,.9),#000 1810%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__toolbar {
    position: absolute;
    right: 0;
    color: #ffffff;
    top: 0;
    background: rgba(35,35,35,.65);
    width: 60px;
    display: flex;
    justify-content: center;
    height: 45px;
    align-items: center;

    svg {
      fill: #ffffff;
      cursor: pointer;

    }
  }
}

.store-directory {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 4px 4px 20px 0 rgba(197, 197, 197, 0.25);
  padding: 40px 24px 16px 24px;
  max-width: 1172px;
  margin: 0 auto;
  margin-bottom: 40px;
  z-index: 2;
  position: relative;

  @media only screen and (max-width: 991px) {
    margin: 0 -16px;
    box-shadow: none;
    padding: 40px 16px 16px 16px;
    border-radius: 0;
  }

  &__title {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .row {
    @media only screen and (max-width: 550px) {
      margin: 0 -3.5px;

      .col-6 {
        padding: 0 3.5px;
        margin-bottom: 16px !important;
      }

    }
  }

  &__category {
    display: flex;
    justify-content: space-between;
    max-width: 860px;
    margin: 0 auto;
    color: #727272;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 30px;
    overflow-x: auto;

    @media only screen and (max-width: 768px) {
      margin-left: 20px;
      padding-bottom: 8px;

      &::-webkit-scrollbar {
        width: 70px;
        height: 4px;
        border-radius: 2px;
        margin-top: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #EDEDED;
      }
    }

    &-item {
      margin-right: 31px;
      color: #727272;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-item:hover, &-item.active {
      color: #005EA8;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .store {
    border-radius: 4px;
    border: 1px solid #EDEDED;
    background: #FFF;
    padding: 12px 12px 20px 12px;
    height: 100%;

    @media only screen and (max-width: 768px) {
      padding: 6px 6px 11px 6px;
    }

    &__image {
      img {
        width: 100%;
      }
    }

    &:hover {
      cursor: pointer;
      .store__name {
        color: #005EA8;
      }
    }

    &__name {
      margin-top: 16px;
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      white-space: pre-wrap;

      @media only screen and (max-width: 768px) {
        font-size: 15px;
      }
    }
  }
}

.our-location {
  max-width: 1172px;
  margin: 0 auto;
  margin-bottom: 40px;

  @media only screen and (max-width: 991px) {
    margin-top: 32px;
    .col-md-3:first-child {
      margin-bottom: 24px;
    }
  }

  .centre-hours {
    border-radius: 4px;
    background: #679F9C;
    padding: 32px 24px;
    height: 100%;

    &__title {
      color: #ffffff;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 24px;
    }

    &__desc {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 26px;
    }

    &__info {
      margin-bottom: 24px;
    }

    &__date {
      color: #ffffff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 8px;
    }

    &__time {
      color: #AFDBD9;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &__close {
      padding: 8px 14px;
      border-radius: 4px;
      background: #ffffff;
      color: #000000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;

      span {
        color: #000000;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .box-our-location {
    padding: 32px 24px;
    background-color: #DCF3F9;
    border-radius: 4px;
    height: 100%;

    @media only screen and (max-width: 991px) {
      margin-top: 32px;
      padding: 32px 24px 0 24px;
    }

    &__map {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__box-map {
      width: 473px;
      height: 345px;
      flex-shrink: 0;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &__title {
      color: #000000;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 24px;
    }

    &__address {
      margin-left: 32px;

      @media only screen and (max-width: 768px) {
        margin-left: 0;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }

    &__name {
      color: #000000;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 8px;
    }

    &__address-detail {
      color: #000000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      max-width: 155px;

      @media only screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }
}

.modal {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  &__title {
    color: #000000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  &__icon-close {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: -10px;
    right: -10px;

    img {
      cursor: pointer;
    }
  }

  &__list-holiday {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

}

.holiday {
  display: flex;
  &__name {
    color: #000000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-right: 5px;
  }

  &__close {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
}

.list-utilities {
  max-width: 1172px;
  margin: 0 auto;
  margin-bottom: 50px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 32px;
    padding-top: 30px;
  }

  &__content {
    border-radius: 4px;
    background: #DCF3F9;
    padding: 40px 24px 32px 24px;
  }

  .utility {

    @media only screen and (max-width: 991px) {
      margin-bottom: 0;
      margin-right: 0;
      padding-bottom: 24px;
    }

    &:first-child {

      max-width: 278px;

      @media only screen and (max-width: 991px) {
        max-width: 100%
      }

      .utility__title {
        max-width: 158px;
      }

      .utility__description {
        max-width: 278px;
      }
    }

    &:nth-child(2) {
      .utility__title {
        max-width: 215px;
      }

      .utility__description {
        max-width: 224px;
      }
    }

    &:nth-child(3) {
      .utility__title {
        max-width: 278px;
      }

      .utility__description {
        max-width: 250px;
      }
    }

    &:nth-child(4) {
      .utility__title {
        max-width: 135px;
      }

      .utility__description {
        max-width: 224px;
      }
    }



    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__image {
       margin-bottom: 12px;
    }

    &__title {
      color: #000000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 12px;
    }

    &__description {
      color: #000000;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      a {
        color: #000000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.contact-us {
  max-width: 1172px;
  margin: 0 auto;
  margin-bottom: 80px;
  padding: 40px 40px 80px 80px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 4px 4px 20px 0 rgba(197, 197, 197, 0.25);

  @media only screen and (max-width: 991px) {
    margin-top: 64px;
  }

  @media only screen and (max-width: 768px) {
    padding: 24px 16px 32px 16px;
    margin-bottom: 40px;
  }

  &__title {
    color: #000000;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__note {
    padding: 0px 0px 30px 0px;
    display: block;
    color: #727272;
    text-align: center;
  }

  &__description {
    color: #727272;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
}

.form {
  margin-top: 43px;

  .row {
    margin: 0 -20px;
  }

  &__type {
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      color: #000000;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      // width: max-content;

      .icon {
        width: 20px;
        height: 20px;
        border: 1px solid #D1D1D1;
        border-radius: 100%;
        margin-right: 8px;
        position: relative;
        min-width: 20px;

        &.selected {
          &:before {
            content: '';
            width: 14px;
            height: 14px;
            background-color: #679F9C;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  &__left, &__right {
    padding: 0 20px;
  }

  &__field {
    margin-bottom: 20px;
  }

  &__label {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 6px;
  }

  input {
    border-radius: 4px;
    border: 1px solid #D1D1D1;
    background: #ffffff;
    width: 100%;
    outline: none;
    padding: 0 15px;
    height: 48px;
    font-size: 16px;
  }

  &__btn-submit {
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0;
    border-radius: 4px;
    background: #679F9C;
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 25px;

    &.mobile {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }
    }
  }

  &__right {
    .form__field {
      margin-bottom: 0;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  textarea {
    width: 100%;
    outline: none;
    border-radius: 4px;
    border: 1px solid #D1D1D1;
    background: #ffffff;
    height: 440px;
    padding: 10px 15px;

    @media only screen and (max-width: 768px) {
      height: 337px;
    }
  }

  .error {
    color: crimson;
    font-size: 14px;
  }
}

.swiper-pagination {
  bottom: -5px!important;
}

.swiper-pagination-bullet {
  background: #86A3AB !important;
  width: 8px !important;
  height: 8px !important;
}

.swiper-pagination-bullet .swiper-pagination-bullet-active {
  background: #BEDDE4 !important;
}
