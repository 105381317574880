
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Lato Light';
  src: url(../assets/fonts/Lato-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Lato Regular';
  src: url(../assets/fonts/Lato-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Bold';
  src: url(../assets/fonts/Lato-Bold.ttf);
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Lato Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --swiper-pagination-color: white;
  --swiper-pagination-bullet-inactive-color: white;
  behavior: 'smooth';
  -webkit-behavior: 'smooth';
  background: white;
}

* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
  //font-family: 'TwCenMT';
}

a {
  text-decoration: none;
}

.max-width-content {
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
}

.icon-play {
  width: 24px;
  height: 24px;
  background: url('../assets/images/action-video.png') -10px -10px;
}

.icon-pause {
  width: 24px;
  height: 24px;
  background: url('../assets/images/action-video.png') -54px -10px;
}

.icon-menu {
  width: 24px; height: 24px;
  background: url('../assets/images/icons.png') -10px -10px;
}

.icon-close-modal {
  width: 20px; height: 20px;
  background: url('../assets/images/icons.png') -10px -54px;
  cursor: pointer;
}

.icon-close {
  width: 20px; height: 20px;
  background: url('../assets/images/icons.png') -54px -10px;
  cursor: pointer;
}
