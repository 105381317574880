.header {
  display: flex;
  height: 80px;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 4px 4px 20px 0 rgba(197, 197, 197, 0.25);

  @media only screen and (max-width: 991px) {
    height: 64px;
  }

  &.mobile {
    height: 100vh;
    align-items: flex-start;
    padding: 24px;
  }

  &__content {
    max-width: 1204px;
    width: 100%;
    display: flex;
    padding: 16px;
    justify-content: space-between;
  }

  &__logo {
    margin-right: 100px;


    @media only screen and (max-width: 991px) {
      margin-right: 0;

      img {
        width: 235px;
      }
    }
  }

  &__menu {
    display: none;

    @media only screen and (max-width: 991px) {
      display: block;
      cursor: pointer;
    }
  }

  .list-menu {
    display: flex;
    max-width: 400px;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 991px) {
      display: none;
    }

    &__item {
      color: #3E3E3E;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;

      &:hover {
        color: #005EA8;
      }
    }
  }

  &__mobile {
    display: none;
    background-color: #ffffff;
    width: 100%;

    @media only screen and (max-width: 991px) {
      display: block;
    }
  }

  &__icon-close {
    display: flex;
    justify-content: flex-end;

    img {
      cursor: pointer;
    }
  }

  &__mobile-menu {
    margin-top: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--item {
      color: #3E3E3E;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 40px;
    }
  }
}


.content-page {
  padding-top: 80px;

  @media only screen and (max-width: 991px) {
    padding-top: 64px;
  }
}

.footer {
  height: 68px;
  background: #DCF3F9;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #000000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.section-contact {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #005EA8;
  z-index: 9999;
  width: 100%;
  color: white;
  font-weight: 600;
  padding: 0 16px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    &>div {
      max-width: 300px;
    }
  }
}

